@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.card-footer {
  margin: 0 -15px;
  width: 100%;
}

.post-detail-sidebar {
  background-color: #f1f1f1;
}

::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
    background: transparent;
  }
}
