@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.asset-header {
  display: flex;
  flex-wrap: nowrap;
}
.asset-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.asset-channel-icons {
  flex-shrink: 0;
}

.fresh-label {
  text-transform: uppercase;

  &::before {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 1em;
    margin-right: 4px;
    vertical-align: text-top;
    width: 1em;
  }
}

.theme--light .v-card__text {
  color: #000;
}

.v-card {
  display: flex;
  flex-direction: column;
}

.v-card__actions {
  border-top: 1px solid $grey-200;
}

.asset__img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: $grey-100;
  padding: 10px;

  @include media('sm-and-up') {
    padding: 20px;
  }
}
.v-image {
  border: 1px solid $grey-200;
  border-radius: 2px;
  flex-grow: 0;
}
.v-responsive__content {
  background-color: $grey-100;
}

.chip-overlay::v-deep {
  .v-overlay__content {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    padding: $spacer * 2;
    width: 100%;
  }
}

.image-overlay::v-deep {
  .v-overlay__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 2rem 4rem;
    width: 100%;

    .more-actions {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 10;
    }
  }

  .v-btn {
    flex-grow: 0;
  }
}
