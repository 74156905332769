@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.content-card {
  &.xs {
    display: flex;
    flex-direction: column;

    .v-card__title {
      word-break: break-word;
    }
  }
}

.v-card__title {
  word-break: normal;
}

.v-dialog {
  @media (max-width: 767px) {
    height: 100vh;
    width: 100vw;
  }
}
