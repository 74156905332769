@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.card-add {
  border-width: 2px;
  border-style: dashed;
  background-color: transparent;

  @include media('xs-only') {
    aspect-ratio: 8/3;
  }

  .v-icon {
    margin-top: -1px;
  }

  &:hover {
    background-color: $grey-100;

    .v-icon,
    .text--secondary {
      color: black !important;
    }
  }
}
