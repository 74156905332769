@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.btn-add {
  height: 24px;
  width: 24px;

  i {
    &::before {
      font-size: 24px;
    }
  }
}

.go-to-calendar {
  font-size: 14px;
  line-height: 16px;
}

.upcoming-posts-sidebar {
  background-color: #f1f1f1;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 16px;
}

.create-new-post-wrap {
  position: sticky;
  bottom: 0;
}

.v-toolbar__title {
  margin-top: 3px;
}
