// The vuetify-loader will automatically bootstrap your variables into Vue CLI's compilation process, overwriting the framework defaults.
// https://vuetifyjs.com/en/customization/sass-variables/

@use "sass:map";

// Globals
$body-font-family: "Roboto";
$heading-font-family: "Roboto";

$border-radius-root: 2px;
$font-size-root: 16px;

$grid-breakpoints: (
  "xs": 0,
  "sm": 600px,
  "md": 960px,
  "lg": 1280px - 16px,
  "xl": 1600px,
  "xxl": 1920px
);

$container-max-widths: (
  "md": map-get($grid-breakpoints, "md") * 0.9375,
  "lg": map-get($grid-breakpoints, "lg") * 0.9375,
  "xl": map-get($grid-breakpoints, "lg") * 0.9375,
  "xxl": map-get($grid-breakpoints, "lg") * 0.9375,
);

$headings: (
  "h1": (
    "size": 4rem,
    "line-height": 1.5em,
  ),
  "h2": (
    "line-height": 1.25em,
    "size": 1.75rem,
  ),
);

// Buttons
$btn-border-radius: 2px;
$btn-font-weight: 400;
