@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.published-posts-table__hint {
  color: $grey-600;
  font-weight: normal;
  font-size: 12px;
}
.v-icon {
  color: $black;
}
.store-link {
  color: black;

  &:hover {
    color: $brand-red;
  }
}
