@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-card {
  @include media('xs-only') {
    margin-bottom: 1rem;
  }
}
.card-content {
  flex: 1;
  height: 100%;
  position: relative;

  .card-text {
    position: absolute;
    padding: inherit;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.preview__asset {
  width: 100%;
  min-height: 120px;
  aspect-ratio: 1;
  object-fit: cover;
  display: block;
}

.preview__cols {
  display: flex;
  flex-wrap: nowrap;
}
.preview__col {
  @include media('xs-only') {
    &:first-child {
      width: 40%;
      max-width: 120px;
    }
    &:last-child {
      flex-grow: 1;
    }
  }

  @include media('sm-and-up') {
    width: 50%;
  }
}

.post-type,
.mso-post-region {
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mso-post-region {
  margin-top: 2px;
  color: $black;
  font-weight: 600;

  .in-progress {
    color: $grey-500;
  }
}

.type-recommended {
  color: $brand-red;
}

.type-store {
  color: $brand-blue;
}

.img-none {
  width: 100%;
  aspect-ratio: 1;
  background-color: #f1f1f1;
}
